import moment from "moment"

const config = {
    backend_urls: {
        default: "https://tcmx.kaiboo.ltd",
        // default: "https://tcmx.zcmu.edu.cn",
        real_host: "http://172.16.1.185:5000",
    },
    frontend_url: "https://brain.kaiboo.ltd",
    // backend_url:"https://tcmx.zcmu.edu.cn/tcmx/api",
    // frontend_url: "https://smarttcm.zcmu.edu.cn",
    backend_url:"https://tcmx.kaiboo.ltd/tcmx/api",
    // backend_url:"https://smarttcm.zcmu.edu.cn/apis/tcmx/api",
    mock: false,
    debug: true,
    start_time: new moment()
}
export default config