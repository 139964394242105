<template>
    <div>
        <particles />
        <div class="head_background" :style="{ width: `${container_width}px` }">
            <brain ref="brain" :style="{ position: 'absolute', top: `${-brain_width * 0.32}px`, left: `${-brain_width * 0.36}px` }" :width="brain_width" />
            <div class="text" :style="{ height: `${container_width * 12}px`, width: `${container_width * 12}px`, top: `${-0.293 * 6 * container_width}px`, left: `${-0.293 * 3.5 * container_width}px` }">
                <span v-for="(item, index) in background_text" :key="index" :style="{ transform: `rotateZ(${(360 / background_text.length) * index}deg)` }">{{ item }}</span>
            </div>
        </div>
        <div class="head_container" :style="{ width: `${container_width}px` }">
            <div class="text">
                <strong style="line-height:24px;font-size:14px">本健康评测报告由</strong>
                <div>凯铂医疗装备（杭州）有限公司</div>
                <!-- <div>浙江中医药大学 中医药大数据与智慧健康研究中心</div> -->
                <!-- <div>(2020年浙江省提升地方高校建设专项<br>——中医大数据诊断与治疗分析平台)</div> -->
                <strong style="line-height:24px;font-size:14px">提供</strong>
            </div>
        </div>
        <div class="userinfo_background" :style="{ width: `${container_width}px` }">
            <div class="padding"></div>
            <div class="nickname">{{ nickname }}</div>
            <div class="avatar" :style="{ background: `#fff` }"></div>
            <!-- <div class="avatar" :style="{ backgroundImage: `linear-gradient(to right, rgba(240, 240, 240, 1), rgba(255, 255, 255, 0)), url(${avatar})` }"></div> -->
        </div>
        <div class="userinfo_container" :style="{ width: `${container_width}px` }">
            <div>姓名：{{ user_name }}</div>
            <div>年龄：{{ user_age }}</div>
            <div>BMI：{{ user_bmi.toFixed(2) }}</div>
            <div>体质：{{ user_main_TiZhi }}</div>
            <div>测评时间：{{ user_test_time }}</div>
            <div class="record">
                <div @click="gohistory()">历史报告 ></div>
                <div
                    style="margin-top: 10px;
                "
                    @click="gobaseinfo()"
                >
                    基本信息 >
                </div>
            </div>
        </div>
        <div :style="{ width: `${container_width}px` }" style=" margin: 0px auto 20px;">
            <!-- <van-tabs :active="active">
                <van-tab title="基础健康报告"> -->
            <!-- 健康体检报告  -->
            <div>
                <div class="health_container" :style="{ width: `${container_width}px` }">
                    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="fire-o" font="20px"> 基础健康报告 </van-notice-bar>
                    <!-- <div style="text-align:center;margin-top:-50px;line-height:40px">健康体检报告</div> -->
                    <div id="health_chart" ref="health_chart" :style="{ width: `${container_width}px`, height: '300px', marginTop: '-20px' }"></div>
                    <div style="text-align:center;margin-top:-50px;line-height:20px"><strong>发病风险</strong></div>
                    <div id="disease_chart" ref="disease_chart" :style="{ width: `${container_width}px`, height: '300px', marginTop: '-50px' }"></div>
                </div>
                <div class="organ_container" :style="{ width: `${container_width}px` }">
                    <div id="organ_chart" ref="organ_chart" :style="{ width: `${container_width}px`, height: '300px' }"></div>
                    <van-tabs type="card" color="rgb(114 226 73)" style="opacity:0.8">
                        <van-tab title="心" class="organ_tab">
                            <organ organ="heart" :width="200" />
                            <div style="display:flex;flex-direction:column;justify-content:space-evenly;">
                                <div>脏器分值 {{ organ_score.心.toFixed(1) }}</div>
                                <div>
                                    <span>健康程度 </span>
                                    <van-tag color="#2ed573" v-if="organ_score.心 > 92">健康</van-tag>
                                    <van-tag color="#70a1ff" v-else-if="organ_score.心 > 83">良好</van-tag>
                                    <van-tag color="#ffa502" v-else-if="organ_score.心 > 73">一般</van-tag>
                                    <van-tag color="#eb3b5a" v-else>预警</van-tag>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="肝" class="organ_tab">
                            <organ organ="liver" :width="200" />
                            <div style="display:flex;flex-direction:column;justify-content:space-evenly;">
                                <div>脏器分值 {{ organ_score.肝.toFixed(1) }}</div>
                                <div>
                                    <span>健康程度 </span>
                                    <van-tag color="#2ed573" v-if="organ_score.肝 > 92">健康</van-tag>
                                    <van-tag color="#70a1ff" v-else-if="organ_score.肝 > 83">良好</van-tag>
                                    <van-tag color="#ffa502" v-else-if="organ_score.肝 > 73">一般</van-tag>
                                    <van-tag color="#eb3b5a" v-else>预警</van-tag>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="脾" class="organ_tab">
                            <organ organ="stomach" :width="200" />
                            <div style="display:flex;flex-direction:column;justify-content:space-evenly;">
                                <div>脏器分值 {{ organ_score.脾.toFixed(1) }}</div>
                                <div>
                                    <span>健康程度 </span>
                                    <van-tag color="#2ed573" v-if="organ_score.脾 > 92">健康</van-tag>
                                    <van-tag color="#70a1ff" v-else-if="organ_score.脾 > 83">良好</van-tag>
                                    <van-tag color="#ffa502" v-else-if="organ_score.脾 > 73">一般</van-tag>
                                    <van-tag color="#eb3b5a" v-else>预警</van-tag>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="肺" class="organ_tab">
                            <organ organ="lung" :width="200" />
                            <div style="display:flex;flex-direction:column;justify-content:space-evenly;">
                                <div>脏器分值 {{ organ_score.肺.toFixed(1) }}</div>
                                <div>
                                    <span>健康程度 </span>
                                    <van-tag color="#2ed573" v-if="organ_score.肺 > 92">健康</van-tag>
                                    <van-tag color="#70a1ff" v-else-if="organ_score.肺 > 83">良好</van-tag>
                                    <van-tag color="#ffa502" v-else-if="organ_score.肺 > 73">一般</van-tag>
                                    <van-tag color="#eb3b5a" v-else>预警</van-tag>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="肾" class="organ_tab">
                            <organ organ="renal" :width="200" />
                            <div style="display:flex;flex-direction:column;justify-content:space-evenly;">
                                <div>脏器分值 {{ organ_score.肾.toFixed(1) }}</div>
                                <div>
                                    <span>健康程度 </span>
                                    <van-tag color="#2ed573" v-if="organ_score.肾 > 92">健康</van-tag>
                                    <van-tag color="#70a1ff" v-else-if="organ_score.肾 > 83">良好</van-tag>
                                    <van-tag color="#ffa502" v-else-if="organ_score.肾 > 73">一般</van-tag>
                                    <van-tag color="#eb3b5a" v-else>预警</van-tag>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
                <div class="text_container" :style="{ width: `${container_width}px` }">
                    <div id="TiZhi_chart" ref="TiZhi_chart" :style="{ width: `${container_width}px`, height: '250px',background:'#fff' }"></div>
                    <!-- <div v-if="!aLshow">
                        <van-collapse v-model="active_organ" :accordion="!aLshow">
                            <van-collapse-item :title="item.text_type" :name="index+''" v-for="(item,index) in text_list" :key="item.text_type">
                                <div v-html="item.rich_text"></div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                    <div v-else> -->
                    <van-collapse v-model="active_organ2">
                        <van-collapse-item :title="item.text_type" :name="index+''" v-for="(item,index) in text_list" :key="item.text_type">
                            <div v-html="item.rich_text"></div>
                        </van-collapse-item>
                    </van-collapse>
                    <!-- </div> -->
                    <van-notice-bar color="#1989fa" background="#ecf9ff" :scrollable="false">
                        <div class="not">
                            <div class="staa">对基础健康测评不满意？</div>
                            <div>
                                <van-button @click="gostep1('主测评')" block round type="primary" size="small">点击重新评测</van-button>
                            </div>
                        </div>
                    </van-notice-bar>
                </div>
                <div class="button_container" :style="{ width: `${container_width}px` }">
                    <van-button @click="goevaluation()" block round type="primary">进入单科测评</van-button>
                </div>
            </div>

            <!-- 放在此处 -->
        </div>
        <!-- <pfab/> -->
    </div>
</template>

<script>
import particles from "../components/particles";
import brain from "../components/brain";
import organ from "../components/organ";
import Pfab from "../components/pfab.vue";

import * as echarts from "echarts/core";
import { BarChart, GaugeChart, RadarChart, PieChart } from "echarts/charts";
import {
    GridComponent,
    TitleComponent,
    LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
    GridComponent,
    GaugeChart,
    BarChart,
    RadarChart,
    PieChart,
    TitleComponent,
    LegendComponent,
    CanvasRenderer,
]);
export default {
    components: {
        particles,
        brain,
        organ,
        Pfab,
    },
    data() {
        return {
            wx: new this.$common.WX(),
            brain_width: 0,
            container_width: 0,
            background_text: "",
            avatar: "",
            nickname: "微信昵称",
            user_name: "用户姓名",
            user_test_time: "2020-20-20 20:20:20",
            user_sex: "男",
            user_height: 180,
            user_weight: 65,
            user_age: 23,
            user_bmi: 20,
            user_main_TiZhi: "",
            user_diastolic: 75,
            user_systolic: 125,
            active_organ: "",
            disease_chart: null,
            health_chart: null,
            organ_chart: null,
            TiZhi_chart: null,
            organ_score: {
                心: 0,
                肝: 0,
                脾: 0,
                肺: 0,
                肾: 0,
            },
            text_list: [],
            activeName: ["0","1", "2"],
            activeName1: [],
            active: 1,
            dkList: [],
            // 分科测评列表
            reportList: {},
            // 单科问卷内容
            single_subject_report: {},
            aLshow: false,
            active_organ2: ["0" ,"1", "2", "3", "4", "5", "6"],
            jcDate: {},
        };
    },
    watch: {},
    methods: {
        gohistory() {
            this.$router.push({ path: "/history" });
        },
        initDiseaseChart(disease_result_list) {
            let echart = echarts.init(this.$refs.disease_chart);
            this.disease_chart = echart;
            let disease_list = [];
            let value_list = [];
            for (let key in disease_result_list) {
                disease_list.push(key);
                value_list.push(disease_result_list[key]);
            }
            echart.setOption({
                xAxis: {
                    type: "category",
                    data: disease_list,
                    axisLabel: {
                        formatter: (label) => {
                            return label.split("").join("\n");
                        },
                    },
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        data: value_list,
                        type: "bar",
                    },
                ],
                label: {
                    show: true,
                    position: "top",
                    formatter: (value) => {
                        return parseInt(value.value * 100) / 100 + "%";
                    },
                },
            });
        },
        initHealthChart(health) {
            let echart = echarts.init(this.$refs.health_chart);
            this.health_chart = echart;
            echart.setOption({
                series: [
                    {
                        type: "gauge",
                        axisLine: {
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.5, "#FF6E76"],
                                    [0.75, "#FDDD60"],
                                    [0.9, "#58D9F9"],
                                    [1, "#7CFFB2"],
                                ],
                            },
                        },
                        pointer: {
                            icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                            length: "12%",
                            width: 20,
                            offsetCenter: [0, "-60%"],
                            itemStyle: {
                                color: "auto",
                            },
                        },
                        axisTick: {
                            length: 12,
                            lineStyle: {
                                color: "auto",
                                width: 1.5,
                            },
                        },
                        splitLine: {
                            length: 20,
                            lineStyle: {
                                color: "auto",
                                width: 3,
                            },
                        },
                        axisLabel: {
                            show: false,
                            color: "#464646",
                            // fontSize: 20,
                            distance: -60,
                            formatter: function (value) {
                                if (value == 95) {
                                    return "健康";
                                } else if (value == 87) {
                                    return "良好";
                                } else if (value == 62) {
                                    return "一般";
                                } else if (value == 40) {
                                    return "较差";
                                }
                            },
                        },
                        // detail: {
                        //     offsetCenter: [0, '0%'],
                        //     valueAnimation: true,
                        //     color: 'auto',
                        // },
                        data: [
                            {
                                value: parseInt(health),
                                name: "健康态评分",
                            },
                        ],
                    },
                ],
            });
        },
        initOrganChart(organ_score) {
            let echart = echarts.init(this.$refs.organ_chart);
            this.organ_chart = echart;
            let value_list = [];
            ["心", "肝", "脾", "肺", "肾"].forEach((item) => {
                for (let organ in organ_score) {
                    if (organ == item) {
                        // 为了让变化幅度更加明显，雷达图的坐标轴范围设置为60~100，所以这边小于60的数据直接设置为60，相当于在原点
                        value_list.push(
                            organ_score[organ] < 60 ? 60 : organ_score[organ]
                        );
                        break;
                    }
                }
            });
            echart.setOption({
                radar: {
                    indicator: [
                        { name: "心", min: 60, max: 100 },
                        { name: "肝", min: 60, max: 100 },
                        { name: "脾", min: 60, max: 100 },
                        { name: "肺", min: 60, max: 100 },
                        { name: "肾", min: 60, max: 100 },
                    ],
                    name: {
                        textStyle: { color: "#3e3e3e" },
                    },
                },
                series: [
                    {
                        type: "radar",
                        data: [{ value: value_list }],
                        itemStyle: { color: "#B3E4A1" },
                        areaStyle: { opacity: 0.7 },
                    },
                ],
            });
        },
        initTiZhiChart(TiZhi_list) {
            let echart = echarts.init(this.$refs.TiZhi_chart);
            this.TiZhi_chart = echart;
            let data = [];
            // let series = [];
            // let push = (key) => {
            //     series.push({
            //         name: key,
            //         type: 'bar',
            //         stack: 'total',
            //         label: {
            //             show: true,
            //             formatter: (v) => {
            //                 return `${v.seriesName}\n\n${v.value}`;
            //             },
            //         },
            //         emphasis: { focus: 'series' },
            //         data: [TiZhi_list[key]],
            //     });
            // };
            let push = (key) => {
                data.push({ name: key, value: TiZhi_list[key].toFixed(2) });
            };
            for (let key in TiZhi_list) {
                if (key == "平和质") push(key);
            }
            for (let key in TiZhi_list) {
                if (key != "平和质") push(key);
            }
            // echart.setOption({
            //     xAxis: {
            //         show: false,
            //         type: 'value',
            //     },
            //     yAxis: {
            //         show: false,
            //         type: 'category',
            //         data: ['体质'],
            //     },
            //     series,
            // });
            echart.setOption({
                series: [
                    {
                        name: "体质",
                        type: "pie",
                        radius: ["15%", "50%"],
                        itemStyle: {
                            // borderRadius: 5,
                            // borderColor: 'rgba(255,255,255,0.2)',
                            // borderWidth: 5,
                        },
                        label: {
                            // alignTo: 'edge',
                            ellipsis: true,
                            formatter: "{name|{b}}\n{time|{c}}",
                            // minMargin: 5,
                            edgeDistance: 20,
                            lineHeight: 18,
                            rich: { time: { color: "#999" } },
                        },
                        labelLayout: function (params) {
                            var isLeft =
                                params.labelRect.x < echart.getWidth() / 2;
                            var points = params.labelLinePoints;
                            points[2][0] = isLeft
                                ? params.labelRect.x
                                : params.labelRect.x + params.labelRect.width;
                            return { labelLinePoints: points };
                        },
                        data,
                    },
                ],
            });
        },
        getReport() {
            let setData = (data) => {
                this.avatar = data.avatar;
                this.nickname = data.nickname;
                this.user_name = data.name;
                this.user_test_time = data.report["评测时间"];
                this.user_sex = data.sex;
                this.user_height = data.height;
                this.user_weight = data.weight;
                this.user_age = data.age;
                this.user_bmi = data.bmi;
                this.user_diastolic = data.diastolic;
                this.user_systolic = data.systolic;
                this.initDiseaseChart(data.report["发病风险"]);
                this.initHealthChart(data.report["健康得分"]);
                this.initOrganChart(data.report["脏器得分"]);
                this.initTiZhiChart(data.report["体质得分"]);
                this.organ_score = data.report["脏器得分"];
                this.user_main_TiZhi = data.report["主体质"];
                let text_order = ["主要特征", "原因分析", "发病倾向"];
                text_order.forEach((item) => {
                    for (let i = 0; i < data.text.length; i++) {
                        if (data.text[i].text_type == item) {
                            this.text_list.push(data.text[i]);
                            data.text.splice(i, 1);
                            break;
                        }
                    }
                });
                this.text_list = this.text_list.concat(data.text);
            };
            if (this.$route.query.report_id) {
                this.$api("getReport", {
                    report_id: this.$route.query.report_id,
                }).then((res) => {
                    setData(res);
                    this.jcDate = res;
                });
            } else {
                this.$api("getReport").then((res) => {
                    setData(res);
                    this.jcDate = res;
                });
            }
        },
        // 获取分科问卷id
        getHistoryReportList() {
            this.$api("getHistoryReportList").then((res) => {
                this.reportList = res.single_subject;
            });
        },
        // 获取测评列表
        getList() {
            this.$api("getList", { type_text: 0, title_text: "病种证型" }).then(
                (res) => {
                    this.dkList = res;
                }
            );
        },
        // 查看单个问卷报告
        getSingleSubjectReport(type) {
            if (this.reportList[type].length == 0) {
                return this.$toast.fail("你还从未测评，请测评！");
            }
            let questionnaire_report_id = this.reportList[type][0].id;
            this.$router.push({
                path: "/single",
                query: {
                    questionnaire_report_id,
                },
            });
        },

        goevaluation() {
            // evaluation
            this.$router.push({ path: "/evaluation" });
        },
        gobaseinfo() {
            this.$router.push({ path: "/healthrecord?ct=2" });
        },
        gostep1(data) {
            this.$router.push({ path: "/step1", query: { type: data } });
        },
    },
    created() {
        let f = () => {
            let flag = true;
            if (this.$store.state.avatar) {
                this.avatar = this.$store.state.avatar;
            } else {
                flag = false;
            }
            if (!flag) setTimeout(f, 20);
        };
        setTimeout(f, 20);
    },
    mounted() {
        window.onresize = () => {
            let container_width = document.body.clientWidth * 0.85;
            let brain_width = container_width * 0.2;
            this.brain_width = brain_width;
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.container_width = calc([920, 960], [960, 1280], [-40, 1, 0]);
            this.brain_width = calc(
                [552, 576],
                [920, 960],
                [0, 0.6, 0],
                this.container_width
            );
            this.background_text = "中医智能健康大脑 ".repeat(
                parseInt(this.container_width * 0.045)
            );
            if (this.health_chart != null)
                this.health_chart.resize({ width: this.container_width });
            if (this.disease_chart != null)
                this.disease_chart.resize({ width: this.container_width });
        };
        this.getReport();
        this.getHistoryReportList();
        this.getList();
        window.onresize();
        setTimeout(() => {
            if (this.$route.query.one == undefined) {
                return;
            } else {
                this.aLshow = true;
            }
        }, 250);
    },
};
</script>

<style scoped>
.cent {
    text-align: center;
}
.topa {
    margin-top: 10px;
}
.head_background {
    position: absolute;
    background: rgba(64, 64, 64, 0.6);
    height: 120px;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
.head_background > .brain {
    position: absolute;
}
.head_background > .text {
    position: absolute;
    animation: rotate 60s linear infinite;
    color: rgba(35, 125, 228, 0.555);
    font-size: 80px;
}
.head_background > .text > span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}
@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}
.head_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.4);
    margin: 20px auto;
    overflow: hidden;
    border-radius: 10px;
    height: 120px;
    z-index: 1000;
    position: relative;
}
.head_container > .text {
    color: white;
    font-size: 12px;
    text-align: center;
    font-weight: 100;
    line-height: 16px;
}
.userinfo_background {
    height: 160px;
    border-radius: 10px;
    overflow: hidden;
    /* opacity: 0.88; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px auto 20px;
}
.userinfo_background > .padding {
    background: #fff;
    height: 100%;
    width: calc(100% - 158px);
    position: absolute;
    left: 0;
}
.userinfo_background > .avatar {
    position: absolute;
    right: 0;
    background-repeat: round;
    width: 160px;
    height: 100%;
}
.userinfo_container {
    box-sizing: border-box;
    padding: 10px 15px;
    height: 160px;
    border-radius: 10px;
    position: relative;
    margin: auto;
    overflow: hidden;
    margin: 0px auto 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.userinfo_background > .nickname {
    position: absolute;
    font-size: 50px;
    color: #a2a2a2;
    top: 120px;
    left: -30px;
}
.health_container {
    height: 550px;
    border-radius: 20px;
    position: relative;
    margin: 0px auto 20px;
    /* background: rgba(240, 240, 240, 0.8); */
    background: rgba(240, 240, 240);
    overflow: hidden;
    /* opacity: 0.8; */
}
.organ_container {
    height: 550px;
    border-radius: 20px;
    position: relative;
    margin: 0px auto 20px;
    /* background: rgba(240, 240, 240, 0.8); */
    background: rgba(240, 240, 240);

    overflow: hidden;
}

.organ_tab {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    padding: 0px 10px;
}
.text_container {
    height: auto;
    border-radius: 20px;
    position: relative;
    margin: 0px auto 20px;
    /* background: rgba(240, 240, 240, 0.8); */
    background: rgba(240, 240, 240);

    overflow: hidden;
    /* opacity: 0.8; */
}
.text_container2 {
    height: 450px;
    border-radius: 20px;
    position: relative;
    margin: 0px auto 20px;
    /* background: rgba(240, 240, 240, 0.8); */
    background: rgba(240, 240, 240);

    overflow: auto;
    /* opacity: 0.8; */
}
.button_container {
    height: 60px;
    margin: 0px auto 20px;
}
/* .userinfo_container > .avatar {
    width: 160px;
    height: 160px;
    position: absolute;
    right: 0;
    background-image: linear-gradient(to right, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0)), url(https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTI21QVm1bCMuUvibC3rocWv1KQhAtCGNzo4295F5ODCUjX9z9Dlopgia84Ya58A0M4WCQdnQauISKcA/132);
    background-repeat: round;
} */
/* .userinfo_container > .avatar_cover:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    box-shadow: 0 0 50px 30px #ffffff inset;
}
.userinfo_container > .avatar_cover > .avatar {
    width: 100%;
    height: 100%;
} */
.staa {
    display: flex;
    align-content: center;
    font-size: 18px;
}
.lvuetext {
    /* border: 1px solid black; */
    font-size: 12px;
    color: yellowgreen;
}
.redtext {
    /* border: 1px solid black; */
    font-size: 12px;
    color: red;
}
.titleo {
    border-left: 3px rgb(199, 244, 18) solid;
    line-height: 25px;
    padding-left: 10px;
}
.van-tab {
    color: black;
    background: rgba(240, 240, 240, 0.8);
}
.eom {
    height: 450px;
    background-color: #fff;
    overflow: hidden;
}
.not {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.record {
    position: absolute;
    right: 15px;
    top: 15px;
}
</style>
